
<template>
    <b-container fluid>
        <iq-card>
            <template v-slot:headerTitle>
                <h4 class="card-title">{{ $t('farm_config.machine_type') }} {{ $t('globalTrans.search') }}</h4>
            </template>
            <template v-slot:body>
              <b-row>
                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                  <b-form-group
                    class="row"
                    label-cols-sm="5"
                    :label="$t('dae_config.instrument_name')"
                    label-for="name"
                    >
                    <b-form-input
                    id="name"
                    :placeholder="$t('dae_config.instrument_name')"
                    v-model="search.name"
                    ></b-form-input>
                  </b-form-group>
                </b-col>
                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                  <b-button type="button" variant="primary" @click="searchData">{{ $t('globalTrans.search') }}</b-button>
                </b-col>
              </b-row>
            </template>
        </iq-card>
        <b-row>
          <b-col md="12">
            <iq-card>
                <template v-slot:headerTitle>
                    <h4 class="card-title">{{ $t('farm_config.machine_type_list') }} </h4>
                </template>
                <template v-slot:headerAction>
                    <b-button variant="primary btn_add_new" v-b-modal.modal-4 @click="resetId">
                        <i class="fas fa-plus"></i>{{ $t('globalTrans.add_new') }}
                    </b-button>
                </template>
                <template v-slot:body>
                    <b-overlay :show="loadingState">
                        <b-row>
                            <b-col md="12" class="table-responsive">
                                <b-table thead-class="table_head" striped :emptyText="$t('globalTrans.noDataFound')" show-empty bordered hover :items="listData" :fields="columns" aria-hidden="loading | listReload ? 'true' : null">
                                    <template v-slot:cell(index)="data">
                                        {{ $n(data.index + pagination.slOffset) }}
                                    </template>
                                    <template v-slot:cell(status)="data">
                                        <span class="badge badge-success" v-if="data.item.status == 1">{{$t('globalTrans.active')}}</span>
                                        <span class="badge badge-danger" v-else>{{$t('globalTrans.inactive')}}</span>
                                    </template>
                                    <template v-slot:cell(action)="data">
                                        <!-- <b-button v-b-modal.modal-4 variant=" iq-bg-success mr-1 mb-1" size="sm" @click="edit(data.item)"><i class="ri-ball-pen-fill m-0"></i></b-button>
                                        <b-button :variant="data.item.status === 1 ? ' iq-bg-success' : '  iq-bg-danger'" size="sm" @click="remove(data.item)">
                                            <i class="fas" :class="data.item.status ? 'fa-toggle-on' : 'fa-toggle-off'"></i>
                                        </b-button> -->
                                        <a href="javascript:" class="btn_table_action table_action_edit" title="Edit" v-b-modal.modal-4 size="sm" @click="edit(data.item)">
                                            <i class="ri-ball-pen-fill"></i>
                                        </a>
                                        <a href="javascript:" class="btn_table_action table_action_view" title="Status" v-if="data.item.status === 1" @click="remove(data.item)">
                                            <i class="fas fa-toggle-on"></i>
                                        </a>
                                        <a href="javascript:" class="btn_table_action table_action_toggle" title="Status" v-if="data.item.status === 2" @click="remove(data.item)">
                                          <i class="fa fa-toggle-off"></i>
                                        </a>
                                    </template>
                                </b-table>
                                <b-pagination
                                    align="center"
                                    v-model="pagination.currentPage"
                                    :per-page="pagination.perPage"
                                    :total-rows="pagination.totalRows"
                                    @input="searchData"
                                    />
                            </b-col>
                        </b-row>
                    </b-overlay>
                </template>
            </iq-card>
          </b-col>
        </b-row>
        <b-modal id="modal-4" size="lg" :title="formTitle" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
            <Form :id="editItemId" :key="editItemId"/>
        </b-modal>
    </b-container>
</template>
<script>
import Form from './Form'
import RestApi, { incentiveGrantServiceBaseUrl } from '@/config/api_config'
import { machineTypeList, machineTypeToggleStatus } from '../../api/routes'
import ModalBaseMasterList from '@/mixins/modal-base-master-list'
import flatpickr from 'flatpickr'

export default {
  mixins: [ModalBaseMasterList],
  components: {
    Form
  },
  data () {
    return {
      search: {
        name: ''
      },
      rows: [],
      item: ''
    }
  },
  mounted () {
    flatpickr('#datepicker', {})
  },
  computed: {
    cultivationMethodList: function () {
        const cultivationMethodList = this.$store.state.incentiveGrant.commonObj.cultivationMethodList
        return cultivationMethodList.map(item => {
            if (this.$i18n.locale === 'bn') {
                return { value: item.value, text: item.text_bn }
            } else {
                return { value: item.value, text: item.text_en }
            }
        })
    },
    formTitle () {
       return (this.editItemId === 0) ? this.$t('farm_config.machine_type') + ' ' + this.$t('globalTrans.add') : this.$t('farm_config.machine_type') + ' ' + this.$t('globalTrans.modify')
    },
    columns () {
      const labels = [
          { label: this.$t('globalTrans.sl_no'), class: 'text-center' },
          { label: this.$t('farm_config.machine_type'), class: 'text-center' },
          { label: this.$t('farm_config.machine_description'), class: 'text-center' },
          { label: this.$t('globalTrans.status'), class: 'text-center' },
          { label: this.$t('globalTrans.action'), class: 'text-center' }
        ]

      let keys = []

      if (this.$i18n.locale === 'bn') {
        keys = [
          { key: 'index' },
          { key: 'machine_type_bn' },
          { key: 'description_bn' },
          { key: 'status' },
          { key: 'action' }
        ]
      } else {
        keys = [
          { key: 'index' },
          { key: 'machine_type' },
          { key: 'description' },
          { key: 'status' },
          { key: 'action' }
        ]
      }

      return labels.map((item, index) => {
          return Object.assign(item, keys[index])
        })
    }
  },
  watch: {
  },
  created () {
    this.loadData()
  },
  methods: {
    searchData () {
      this.loadData()
    },
    details (item) {
      this.editItemId = item.id
      this.item = item
    },
    edit (item) {
      this.editItemId = item.id
    },
    remove (item) {
      this.changeStatus(incentiveGrantServiceBaseUrl, machineTypeToggleStatus, item, 'incentive_grant', 'cultivationMethodList')
    },
    loadData () {
      const params = Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage })
      this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
      RestApi.getData(incentiveGrantServiceBaseUrl, machineTypeList, params).then(response => {
        if (response.success) {
          this.$store.dispatch('setList', response.data.data)
          this.paginationData(response.data)
        } else {
          this.$store.dispatch('setList', [])
        }
        this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
      })
    }
  }
}
</script>
